.PollCardHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 16px 16px 16px;
    height: 48px;
}

.PollCardHeader img {
    height: 48px;
}

.PollCardHeader button {
    background-image: url('../../assets/img/more.png');
    background-size: 100%;
    background-color: transparent;
    border: none;
    height: 40px;
    width: 40px;
    box-sizing: border-box;
    cursor: pointer;
}

.left-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.username-label {
    margin-left: 12px;
}