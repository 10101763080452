.FeatureRequestCell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    padding: 5px 20px;
    border-bottom: 1px solid lightgray;
}

.FeatureRequestCell .upvotes-container:hover {
    cursor: pointer;
}

.FeatureRequestCell .text-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow: ellipsis;
}

.FeatureRequestCell .item-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #444444;
}

.FeatureRequestCell .text-content .item-description {
    font-size: 14px;
    color: lightslategray;
}

.FeatureRequestCell .upvotes-container {
    color: #444444;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 10px;
    border: 1px solid lightslategray;
    padding: 10px 12px;
    border-radius: 4px;
}

.FeatureRequestCell * {
    margin: 0;
}