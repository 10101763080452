.PrivacyPolicy .container {
    text-align: left;
    width: 40%;
    margin-left: 30%;
    margin-top: 50px;
}

@media (max-width: 500px) {
    .PrivacyPolicy .container {
        text-align: left;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 50px;
    }
}