.Poll .Footer * {
    color: #787878;
}

.Poll .Footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.Poll .create-button {
    background-color: #FF8C66;
    height: 50px;
    width: 90%;
    margin-left: auto;
    color: white;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-right: auto;
    margin-top: 100px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 5px 15px rgba(red, green, blue, alpha);
}

.Poll .create-button:focus {
    outline: none;
}