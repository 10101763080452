.ProgressBar {
   height: 42px;
   width: 100%;
   text-align: left;
   margin: 15px 0px;
   color: white;
   position: relative;
   display: flex;
   flex-direction: row;
   align-items: center;
}

.ProgressBar .ChosenAnswer {
    border: 2px solid #4DFFDA;
}

.BarBackground {
    height: 40px;
    width: 100%;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.15);
    -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}
.AnimatedBar {
    background-color: rgba(255, 255, 255, 0.30);
    transition: width 0.5s ease-in-out;
    height: 100%;
}

.TitleContainer {
    position: absolute;
    display: flex;
    flex-direction: row;    
    justify-content: space-between;
    width: 100%;
    height: 42px;
    line-height: 42px;
    pointer-events: none;
    padding: 0 12px;
    box-sizing: border-box;
}

.ProgressBar .Title {
    margin-left: 30px;
    padding: 0;
    margin: 0;
    padding-bottom: 10px;
}

.ProgressBar .Percentage {
    margin-right: 30px;
    padding: 0;
    margin: 0;
    padding-bottom: 10px;
}