.Home {
  color: white;
  font-family: "Rubik", sans-serif;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  display: grid;
  grid-template-areas:
    "header header"
    "content mockup"
    "footer footer";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100px 1fr 100px;
  margin: 0;
  position: relative;
  border-top: 10px solid #ff8c66;
}

.Home .hero {
  text-align: left;
  padding-left: 140px;
  padding-right: 9.7%;
  width: 90%;
  display: flex;
  flex-direction: column;
  grid-area: content;
  justify-content: center;
}

.Home .hero-title {
  font-size: 35px;
  font-weight: bold;
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  padding-bottom: 40px;
  color: #444444;
}

.Home .hero-description {
  font-size: 20px;
  padding-bottom: 40px;
  color: black;
  opacity: 0.36;
}

.Home .download-button {
  margin-right: auto;
  font-size: 18px;
  background-color: #ff8c66;
  text-decoration: none;
  color: white;
  border-radius: 8px;
  height: 50px;
  width: 224px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 15px inherit;
  font-weight: bold;
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
}

.Home .download-button:hover {
  cursor: pointer;
}

.Home * {
  padding: 0;
  margin: 0;
}

.Home .mockup {
  max-width: 100%;
  max-height: 75%;
}

.Home .mockup img {
  max-height: 700px;
}

.Home .Footer {
  grid-area: footer;
  align-items: flex-end;
  padding-bottom: 20px;
}

.Home .content {
  grid-area: content;
}

.Home .title {
  grid-area: header;
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: bold;
  font-size: 40px;
  align-self: flex-end;
  text-align: left;
  margin-left: 73px;
  color: #ff8c66;
}

.Home .mockup {
  grid-area: mockup;
}

.Home .Footer a {
  color: rgba(0, 0, 0, 0.36);
}

@media (max-width: 500px) {
  .Home {
    grid-template-areas:
      "header"
      "mockup"
      "content"
      "footer";
    grid-template-columns: 1fr;
    grid-template-rows: 55px 1fr 1fr 100px;
    border-top: 5px solid #ff8c66;
  }

  .Home .title {
    font-size: 25px;
    margin-left: 20px;
  }

  .Home .hero {
    width: 90%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .Home .hero-title {
    font-size: 20px;
    padding-bottom: 25px;
    padding-top: 35px;
  }

  .Home .hero-description {
    font-size: 16px;
    padding-bottom: 25px;
  }

  .Home .mockup {
    margin-top: 30px;
  }

  .Home .mockup img {
    height: 400px;
  }

  .Home .download-button {
    width: 100%;
    font-size: 16px;
  }
}