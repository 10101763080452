.FeatureRequests {
  border-top: 10px solid #ff8c66;
}

.FeatureRequests .title {
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: bold;
  font-size: 40px;
  align-self: flex-end;
  text-align: left;
  margin-left: 73px;
  color: #ff8c66;
}

.FeatureRequests .suggestions-header {
  display: flex;
  text-align: left;
  flex-direction: column;
  padding: 0 20px;
}

.FeatureRequests .table-header {
  background-color: #ff8c66;
  color: white;
  height: 30px;
  text-transform: uppercase;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  width: 20%;
  border-radius: 4px;
  margin-left: auto;
  margin-right: 30px;
}

.FeatureRequests .suggestions-header .heading {
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.suggestion-modal-title {
  margin: 0;
  font-size: 16px;
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: bold;
}

.form-container {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.Modal {
  width: 90%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  background-color: white;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Modal:focus {
  outline: none;
}

.form-title {
  height: 35px;
  font-size: 16px;
  background-color: #ECF0F3;
  border: none;
  padding-left: 10px;
  border-radius: 4px;
  margin-top: 10px;
  font-family: "Rubik", sans-serif;
}

textarea {
  background-color: #ECF0F3;
  border-radius: 4px;
  border: none;
  resize: none;
  margin: 20px 0;
  font-size: 16px;
  padding: 10px;
  font-family: "Rubik", sans-serif;
}

textarea:focus {
  outline: none;
}

.form-title:focus {
  outline: none;
}

.submit-button {
  color: white;
  background-color: #ff8c66;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
}

.FeatureRequests .suggestions-header .more-info {
  font-size: 14px;
  color: lightslategray;
}

.FeatureRequests .suggestions-header * {
  margin: 0;
}

@media (max-width: 500px) {
  .FeatureRequests {
    border-top: 5px solid #ff8c66;
  }

  .FeatureRequests .title {
    font-size: 25px;
    margin-left: 20px;
  }
}