.PollContainer {
    border-radius: 18px;
    margin: 0 16px;
    padding: 24px 16px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    background-size: 100% auto;
    background-position: center;
}

.PollContainerTint::after {
    content: 'hey';
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    opacity: 0.5;
    z-index: -1;
}

.PollContainer h1 {
    color: white;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4;
}