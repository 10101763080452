@font-face {
  font-family: 'Circular-Std';
  /*a name to be used later*/
  src: URL('./assets/font/circular-book.otf');
  /*URL to font*/
}

@font-face {
  font-family: 'Circular-Std-Book';
  /*a name to be used later*/
  src: URL('./assets//font/circular-book.otf');
  /*URL to font*/
}

@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700&display=swap');

.App {
  text-align: center;
  font-family: 'Circular-Std';
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}