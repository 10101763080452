.Header {
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    color: white;
    background-color: #ff8c66;
    font-family: 'Rubik', sans-serif;
}

.Header .download-button {
    width: 68px;
    height: 32px;
    border-radius: 8px;
    border: solid 1px #ffffff;
    background-color: transparent;
    color: white;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}

.header-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    text-align: left;
}

.header-title {
    font-weight: 500;
    font-size: 15px;
    padding: 0;
    margin: 0;
    padding-bottom: 4px;
}

.header-subtitle {
    padding: 0;
    margin: 0;
    font-weight: normal;
    font-size: 14px;
}

.header-logo-icon {
    height: 44px;
    width: 44px;
    margin-right: 10px;
}

.header-left-side {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-left-side:hover {
    cursor: pointer;
}